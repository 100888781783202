import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { RecaptchaVerifier, getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import './App.css';
import { Loader } from './common/components/loader';
import { useAxiosRequestInterceptor } from './core/axios';
import { ENV_CONFIG } from './core/constants/env';

const LazyPublicApp = React.lazy(() => import('./layouts/public'));
const LazySharedApp = React.lazy(() => import('./layouts/shared'));
const LazyPrivateApp = React.lazy(() => import('./layouts/private'));

const App = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { setInterceptor } = useAxiosRequestInterceptor();

  const publicRoutes: RouteObject = useMemo(
    () => ({
      path: '/auth/*',
      element: <LazyPublicApp />,
    }),
    [],
  );

  const sharedRoutes: RouteObject = useMemo(
    () => ({
      path: '/shared/*',
      element: <LazySharedApp />,
    }),
    [],
  );

  const protectedRoutes: RouteObject = useMemo(
    () => ({
      path: '/app/*',
      element: <LazyPrivateApp />,
    }),
    [],
  );

  const defaultRoutes: RouteObject = useMemo(
    () => ({
      path: '*',
      element: <Navigate to={`/auth${search}`} />,
    }),
    [search],
  );

  const routing = useRoutes([defaultRoutes, publicRoutes, protectedRoutes, sharedRoutes]);

  useMemo(() => {
    const firebaseConfig = {
      apiKey: ENV_CONFIG.FIREBASE_API_KEY,
      authDomain: ENV_CONFIG.FIREBASE_AUTH_DOMAIN,
      projectId: ENV_CONFIG.FIREBASE_PROJECT_ID,
      storageBucket: ENV_CONFIG.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: ENV_CONFIG.FIREBASE_MESSAGING_SENDER_ID,
      appId: ENV_CONFIG.FIREBASE_APP_ID,
      measurementId: ENV_CONFIG.FIREBASE_MEASUREMENT_ID,
    };
    initializeApp(firebaseConfig);
    isSupported().then((supported) => (supported ? getAnalytics() : null));
  }, []);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      const currentRoute = window.location.pathname;

      if (currentRoute.startsWith('/shared')) {
        navigate(currentRoute);
      } else {
        if (user) {
          if (currentRoute.startsWith('/app')) {
            navigate(currentRoute);
          } else {
            navigate('/app/home');
          }
        } else {
          navigate('/auth/login');
        }
      }

      setIsLoading(false);
    });

    onIdTokenChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setInterceptor(token);
        });
      }
    });

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
      });
    }
  }, [navigate, setInterceptor]);

  dayjs.extend(localizedFormat);

  if (isLoading) {
    return <Loader />;
  }

  return <React.Suspense fallback={<Loader />}>{routing}</React.Suspense>;
};

export default App;
