import axios, { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { ENV_CONFIG } from './constants/env';

const setAxiosReqInterceptor = (instance: AxiosInstance, accessToken: string) => {
  return instance.interceptors.request.use((config) => {
    if (accessToken && config.headers) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  });
};

const ejectAxiosReqInterceptor = (instance: number) => {
  instance && axios.interceptors.request.eject(instance);
};

const axiosInstance = axios.create({
  baseURL: ENV_CONFIG.API_BASE_URL,
});

const useAxiosRequestInterceptor = () => {
  const setInterceptor = useCallback((accessToken: string) => setAxiosReqInterceptor(axiosInstance, accessToken), []);

  const ejectInterceptor = useCallback(
    (interceptorIds: number[]) => interceptorIds.forEach((instance) => ejectAxiosReqInterceptor(instance)),
    [],
  );

  return {
    setInterceptor,
    ejectInterceptor,
  };
};

export { axiosInstance, useAxiosRequestInterceptor };
