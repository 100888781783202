const ENV_CONFIG = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY as string,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID as string,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
  APP_NAME: process.env.REACT_APP_NAME as string,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL as string,
};

export { ENV_CONFIG };
